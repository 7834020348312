import mainFaqData from '../../assets/data/mainFaq.json';
import Footer from '../../components/Footer/Footer';
import styles from './HomeMobile.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SwiperCore from 'swiper';
import { useEffect, useState } from 'react';
import axiosClient from '../../libs/axiosClient';
import { useQuery } from 'react-query';
import rightArrow from '../../assets/rightArrow.png';
import MainFaqFrameMobile from '../../mobileComponents/MainFaqFrameMobile/MainFaqFrameMobile';
import MainReviewFrame from '../../components/MainReviewFrame/MainReviewFrame';
import { IReviewDataType } from '../../pages/MyReview/MyReview';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { loginCheck } from '../../auth/auth';
import ReviewFilter from '../../components/ReviewFilter/ReviewFilter';
import processImage01 from '../../assets/processImage01.png';
import processImage02 from '../../assets/processImage02.png';
import processImage03 from '../../assets/processImage03.png';
import review from '../../assets/review.png';
import ondocServiceM from '../../assets/ondocServiceM.png';
import { IProductListType } from '../../pages/ProductList/ProductList';
import banner1 from '../../assets/banner/mobileMainBanner01.jpg';
import banner3 from '../../assets/banner/mobileMainBanner03.jpg';
import banner4 from '../../assets/banner/mobileMainBanner04.jpg';
import banner5 from '../../assets/banner/mobileMainBanner05.jpg';
import banner6 from '../../assets/banner/mobileMainBanner06.jpg';
import banner7 from '../../assets/banner/mobileMainBanner07.jpg';
import LazyLoad from 'react-lazy-load';
import { IAdLinkDataType } from '../../pages/Home/Home';
import ADLink from '../../components/ADLink/ADLink';
import { IBannerType } from '../../components/MainBanner/MainBanner';
import Header from '../../components/Header/Header';
import Popup from '../../components/Popup/Popup';
import { useCookies } from 'react-cookie';

const mainFaqList = mainFaqData;
function HomeMobile() {
  const location = useLocation();
  const navigate = useNavigate();
  //배너
  const [activeIdx, setActiveIdx] = useState(0);
  const [slideList, setSlideList] = useState<IBannerType[]>([]);
  const [processList, setProcessList] = useState<IBannerType[]>([]);
  useEffect(() => {
    setSlideList(() => {
      let list: IBannerType[] = [
        // { image: banner1, url: 'productDetail/10028' },
        { image: banner6, url: 'productDetail/1' },
        { image: banner7, url: 'productDetail/1' },
        { image: banner5, url: 'event/28' },
        { image: banner4, url: 'event/27' },
        { image: banner3, url: 'event/29' },
      ];
      return [...list];
    });
  }, []);
  useEffect(() => {
    setProcessList(() => {
      let list: IBannerType[] = [
        { image: processImage01 },
        { image: processImage02 },
        { image: processImage03 },
      ];
      return [...list];
    });
  }, []);
  const [swiper, setSwiper] = useState<SwiperCore>();
  //배너
  //상품
  const { data: productList } = useQuery<IProductListType[]>(
    ['productList', 'all'],
    async () => {
      let queryString = '';
      let ids = JSON.parse(
        sessionStorage.getItem('product_ids') || JSON.stringify([]),
      );
      if (ids.length) {
        queryString = 'add_products=';
        ids.map((id: number, idx: number) => {
          queryString += `${id}${idx !== ids.length - 1 ? ',' : ''}`;
        });
      }
      return await axiosClient
        .get(`/data/product/list?${queryString}`)
        .then((res) => res.data.data);
    },
  );
  //상품
  //프로세스
  const [processSliderIdx, setProcessSliderIdx] = useState(0);
  //프로세스
  //회사소개
  const [infoSwiper, setInfoSwiper] = useState<SwiperCore>();
  const [ondocInfoIndex, setOndocInfoIndex] = useState<number>(1);
  //회사소개
  //의사
  const [doctorSwiper, setDoctorSwiper] = useState<SwiperCore>();
  const [doctorInfoIndex, setDoctorInfoIndex] = useState<number>(1);
  const handleDoctorInfoChange = (doctorSwiper: SwiperCore) => {
    setDoctorInfoIndex(doctorSwiper.realIndex);
  };
  //의사
  const { data: reviewData } = useQuery<IReviewDataType[]>(
    ['main', 'photo_review'],
    () =>
      axiosClient
        .get(
          '/data/product_talk/photo-review-list?&talk_type=상품평가&limit=5&order_by=star',
        )
        .then((res) => res.data.data),
    {
      staleTime: 60000 * 60,
    },
  );
  //review
  //ondocInfo
  const handleOndocInfoChange = (infoSwiper: SwiperCore) => {
    setOndocInfoIndex(infoSwiper.realIndex);
  };

  //기존 상품 url접속시 대응
  useEffect(() => {
    let pn = new URLSearchParams(location.search).get('pn');
    if (!pn) return;
    if (pn == 'product.view') {
      axiosClient
        .get(
          `/data/product/get_new_url?is_blind=N&code=${new URLSearchParams(
            location.search,
          ).get('pcode')}`,
        )
        .then((res) => navigate(res.data.data.url));
    } else if (pn == 'blind_gate') {
      axiosClient
        .get(
          `/data/product/get_new_url?is_blind=Y&code=${new URLSearchParams(
            location.search,
          ).get('bc')}`,
        )
        .then((res) => navigate(res.data.data.url));
    }
  }, []);
  //기존 상품 url접속시 대응
  const [adLinkOpen, setAdLinkOpen] = useState(true);
  const [adLinkData, setAdLinkData] = useState<IAdLinkDataType>();
  // useEffect(() => {
  //   axiosClient.get('/data/product/promotional_product').then((res) => {
  //     if (!Object.keys(res.data.data).length) return;
  //     setAdLinkData(res.data.data);
  //   });
  // }, []);

  const [hasCookie, setHasCookie] = useState(true);
  const [cookies, setCookies] = useCookies();

  useEffect(() => {
    if (cookies['week-close']) setHasCookie(true);
    else setHasCookie(false);
  }, []);

  return (
    <>
      {adLinkOpen && adLinkData && (
        <ADLink
          productId={adLinkData.id}
          productName={adLinkData.product_name}
          price={adLinkData.price}
          discountedPrice={adLinkData.sale_price}
          closer={setAdLinkOpen}
        />
      )}
      {/* {!hasCookie && <Popup />} */}
      <div>
        <Header />
        <div className={styles.bannerSliderWrap}>
          <Swiper
            onSwiper={setSwiper}
            slidesPerView={1}
            spaceBetween={0}
            className={styles.bannerSlider}
            onSlideChange={(e) => setActiveIdx(e.activeIndex)}
          >
            {slideList.map((slide, idx) => (
              <SwiperSlide
                key={idx}
                onClick={() => {
                  if (slide.url) navigate(slide.url);
                }}
              >
                <img
                  src={slide.image}
                  alt="banner"
                  style={{ cursor: slide.url ? 'pointer' : 'unset' }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <ul className={styles.paginationWrap}>
            {slideList.map((banner, idx) => (
              <li
                key={idx}
                onClick={() => swiper?.slideTo(idx, 200)}
                className={idx == activeIdx ? styles.hit : ''}
              ></li>
            ))}
          </ul>
        </div>
        <div className={styles.prescriptionProductWrap}>
          <h3 onClick={() => navigate(`/productList?cuid=488`)}>
            <span>건강식품</span>
            <img src={rightArrow} alt="rightArrow" />
          </h3>
          <span>다이어트에 최적화된 건강한 영양 식품</span>
          <Swiper slidesPerView={1.7} spaceBetween={10}>
            {productList &&
              productList
                ?.filter((product) => product.is_prescription === 0)
                .map((product, dix) => (
                  <SwiperSlide>
                    <div
                      className={styles.productSlideWrap}
                      onClick={() =>
                        navigate(`/productDetail/${product.product_id}`)
                      }
                    >
                      <div className={styles.productThumbnailWrap}>
                        <img
                          src={product.list_image + '?w=530'}
                          alt="productThumbnail"
                        />
                      </div>
                      <div className={styles.productInfoWrap}>
                        <div className={styles.productInfoLeft}>
                          <p>{product.title}</p>
                          <div className={styles.productInfoPrice}>
                            <span>
                              {product.discount_price.toLocaleString()}원
                            </span>
                            {product.price !== 0 && (
                              <span>{product.price.toLocaleString()}원</span>
                            )}
                          </div>
                          <div className={styles.productInfoScore}>
                            <img src={review} alt="review" />
                            <span>
                              {product.review_score_avg?.review_total_count.toLocaleString()}
                            </span>
                          </div>
                        </div>
                        <div className={styles.productInfoRight}></div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
          </Swiper>
        </div>
        <div className={styles.reviewWrap}>
          <h3 onClick={() => navigate(`/review/all`)}>
            <span>실시간 리뷰</span>
            <img src={rightArrow} alt="rightArrow" />
          </h3>
          <span>최신 고객 리뷰를 지금 바로 확인해 보세요.</span>
          <div className={styles.reviewSliderWrap}>
            <LazyLoad>
              <Swiper
                slidesPerView={1.5}
                spaceBetween={10}
                className={styles.reviewSlider}
                breakpoints={{ 700: { slidesPerView: 2.8 } }}
              >
                {reviewData?.map((review) => (
                  <SwiperSlide key={review.id}>
                    <MainReviewFrame review={review} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </LazyLoad>
            {!loginCheck() && <ReviewFilter borderRadius={0} />}
          </div>
        </div>
        <div className={styles.faqWrap}>
          <h3 onClick={() => navigate(`/faq`)}>
            <span>자주 받는 문의 사항</span>
            <img src={rightArrow} alt="rightArrow" />
          </h3>
          <span>커넥트닥터 FAQ</span>
          <ul>
            {mainFaqList.map((faq, idx) => (
              <MainFaqFrameMobile faq={faq} key={idx} />
            ))}
          </ul>
        </div>
        <div className={styles.ondocInfoWrap}>
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            className={styles.infoSlider}
            onSwiper={setInfoSwiper}
            onSlideChange={(swiper) => handleOndocInfoChange(swiper)}
            loop
          >
            <SwiperSlide className={styles.slide1}>
              <img src={processImage01} alt="" />
              <h3>
                <span>원격 진료를</span>
                <br />
                간편하고 정확하게
              </h3>
              <p>
                쉽고 편한 의료 서비스를 제공하여 언제 어디서나
                <br />
                정확한 진료를 받아볼 수 있습니다.
              </p>
            </SwiperSlide>
            <SwiperSlide className={styles.slide2}>
              <h3>
                처방약을
                <br />
                <span>쉽고 빠르게</span>
              </h3>
              <img src={processImage03} alt="" />
              <p>
                의료 서비스 이용에 어려움을 겪는 분들에게
                <br />
                처방한 약을 쉽고 빠르게 배송
              </p>
            </SwiperSlide>
            <SwiperSlide className={styles.slide3}>
              <h3>
                <span>정성과 과학으로</span>
                <br />
                만듭니다.
              </h3>
              <img src={processImage02} alt="" />
              <p>
                검증된 약재와 맞춤 처방으로 안전한 효과를
                <br /> 추구하며, 식약처 인증된 의약용품만을 사용
              </p>
            </SwiperSlide>
          </Swiper>
          <ul className={styles.ondocInfoPaginationWrap}>
            {processList.map((banner, idx) => (
              <li
                key={idx}
                onClick={() => infoSwiper?.slideTo(idx + 1, 200)}
                className={idx === ondocInfoIndex ? styles.hit : ''}
              ></li>
            ))}
          </ul>
        </div>
        <div className={styles.ondocService}>
          <span className={styles.bigText}>
            한방의 지혜와 현대 기술이
            <br /> 결합된 커넥트닥터의
            <br /> 비대면 진료를 만나보세요!
          </span>
          <span className={styles.smallText}>
            병원에 방문하지 않고도 언제 어디서나 간편한
            <br /> 한방 비대면 진료! 커넥트닥터를 통해 <br /> 건강한
            라이프스타일을 구현하세요.
          </span>
        </div>
        <div className={styles.serviceInfoWrap}>
          커넥트닥터는 결제기능 및 배송운영 서비스로 <br /> 진료 및 처방에는
          관여하지 않습니다.
          <br />
          처방 및 진료를 위해 제공하는 정보는 커넥트닥터 <br />
          법무팀의 법적 자문을 받은 의료진
          <br /> 고유의 재산으로 제3자의 무단 침해 및 <br /> 가공 사용의 경우
          법적 책임을 받을 수 있습니다.
        </div>
        <Footer />
      </div>
    </>
  );
}
export default HomeMobile;
